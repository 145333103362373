export default {
  partnerFilter: { filters: [], certificate: null, attr: null },
  objectRangeFilter: [],
  objectsFilter: [],
  authUser: null,
  businessRegions: [],
  geoRegions: [],
  directions: [],
  activeDirections: [],
  productGroups: [],
  products: [],
  objectTypes: [],
  partnerTypes: [],
  partnerAttributes: [],
  objects: [],
  partners: [],
  visibles: [],
  availables: [],
  socials: [],
  activeItemId: null,
  activeLayer: '',
  userLocation: [],
  currentCoords: {
    coords: [],
    zoom: 0
  },
  locationName: '',
  searchResults: [],
  dot: false,
  isChoosingLocation: false,
  phoneMasks: [],
  isLoading: false
}
