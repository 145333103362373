import axios from '@/axios';
import AuthHelper from '@/helpers/AuthHelper';

export default {
  async fetchAuthUser({ commit }) {
    const accessToken = AuthHelper.checkAuth();
    if (accessToken) {
      const response = await axios.get('/api/v1/users/me');
      commit('SET_USER', response.data);
    }
  },
  setLocation({ commit }, { coords, zoom }) {
    commit('SET_LOCATION', coords);
    commit('SET_COORDS', { coords, zoom: zoom || 10 });
  },
  setCurrentLocation({ commit }, config) {
    commit('SET_COORDS', config);
  },
  async fetchBusinessRegions({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/business_regions', { params });
    commit('SET_BUSINESS_REGIONS', response.data.results);
  },
  async fetchGeoRegions({ commit }) {
    const params = { page_size: 1000, ordering: 'name' };
    const response = await axios.get('/api/v1/geographic_regions', { params });
    commit('SET_GEO_REGIONS', response.data.results);
  },
  async fetchDirections({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/directions', { params });
    commit('SET_DIRECTIONS', response.data.results);
  },
  async fetchProductGroups({ commit }) {
    const response = await axios.get('/api/v1/product_groups');
    commit('SET_GROUPS', response.data);
  },
  async fetchProducts({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/products', { params });
    commit('SET_PRODUCTS', response.data.results);
  },
  async fetchSocials({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/social_networks', { params });
    commit('SET_SOCIALS', response.data.results);
  },
  setObjects({ commit }, objects) {
    commit('SET_OBJECTS', objects);
  },
  async fetchFacilityTypes({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/object_types', { params });
    commit('SET_FACILITY_TYPES', response.data.results);
  },
  setPartners({ commit }, partners) {
    commit('SET_PARTNERS', partners);
  },
  async fetchPartnerTypes({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/partner_types', { params });
    commit('SET_PARTNER_TYPES', response.data.results);
  },
  async fetchPartnerAttributes({ commit }) {
    const params = { page_size: 1000 };
    const response = await axios.get('/api/v1/partner_attributes', { params });
    commit('SET_PARTNER_ATTRIBUTES', response.data.results);
  },
  async fetchPhoneMasks({ commit }) {
    const response = await axios.get('/countryPhoneMasks.json');
    commit('SET_PHONE_MASKS', response.data);
  },
  setActiveDirections({ commit }, directions) {
    commit('SET_ACTIVE_DIRECTIONS', directions);
  },
  setActiveLayer({ commit }, layer) {
    commit('SET_ACTIVE_LAYER', layer);
  },
  setSearchResults({ commit }, results) {
    commit('SET_SEARCH_RESULTS', results);
  },
  setActiveItem({ commit }, id) {
    commit('SET_ACTIVE_ITEM', id);
  },
  async setVisible({ state, commit }, bounds) {
      if (state.availables.length > 0) {
        const availables = [...state.availables];
        const visibles = availables.filter(object => object.coords[0] > bounds[0][0]
          && object.coords[0] < bounds[1][0]
          && object.coords[1] > bounds[0][1]
          && object.coords[1] < bounds[1][1]).map(obj => obj.id)
        commit('SET_VISIBLE', visibles);
      } else {
        commit('SET_VISIBLE', []);
      }
  },
  applyFilters({ commit }, list) {
    commit('SET_AVAILABLE', list);
  },
  setPartnerCertificate({ state, commit }, status) {
    commit('SET_PARTNER_FILTER', { ...state.partnerFilter, certificate: status })
  },
  setPartnerAttributes({ state, commit }, status) {
    commit('SET_PARTNER_FILTER', { ...state.partnerFilter, attr: status })
  },
  setObjectRangeFilter({ state, commit }, value) {
    commit('SET_OBJECT_RANGE_FILTER', value)
  },
  setPartnerFilter({ state, commit }, list) {
    const findedGroup = (state.partnerFilter?.filters || []).find(el => el.group === list.groupName)
    let data = {}
    if (findedGroup) {
      const newData = {
          ...findedGroup,
          names: [...new Set(findedGroup.names.includes(list.name) ? [...findedGroup.names.filter(name => name !== list.name)] : [...findedGroup.names, list.name])],
          productIds: [...new Set(findedGroup.productIds.includes(list.groupId) ? [...findedGroup.productIds.filter(ids => ids !== list.groupId)] : [...findedGroup.productIds, list.groupId])]
      }

      data = { filters: state.partnerFilter.filters.map(el => (el.group === newData.group ? newData : el)), certificate: list.certificate, attr: list.attr }
    } else {
      data = { filters: [...state.partnerFilter.filters, { group: list.groupName, names: [list.name], productIds: [list.groupId] }], certificate: list.certificate, attr: list.attr }
    }
    commit('SET_PARTNER_FILTER', { ...data, filters: data.filters.filter(f => f.names.length || f.productIds.length) })
  },
  setObjectsFilter({ state, commit }, list) {
    const findedGroup = state.objectsFilter.find(el => el.group === list.groupName)

    const data = []
    if (findedGroup) {
      const newData = {
        ...findedGroup,
        names: [...new Set(findedGroup.names.includes(list.name) ? [...findedGroup.names.filter(name => name !== list.name)] : [...findedGroup.names, list.name])],
        productIds: [...new Set(findedGroup.productIds.includes(list.groupId) ? [...findedGroup.productIds.filter(ids => ids !== list.groupId)] : [...findedGroup.productIds, list.groupId])]
      }

      data.push(...state.objectsFilter.map(el => (el.group === newData.group ? newData : el)))
    } else {
      data.push(...[...state.objectsFilter, { group: list.groupName, names: [list.name], productIds: [list.groupId] }])
    }
    const filteredData = data.filter(f => f.names.length || f.productIds.length);

    commit('SET_OBJECT_FILTER', filteredData);
  },
  clearPartnerFilter({ state, commit }, name) {
    if (name) {
      const partnerFilter = [...state.partnerFilter.filters];
      const filtered = partnerFilter.filter(el => el.group !== name)
      commit('SET_PARTNER_FILTER', { filters: filtered, certificate: state.partnerFilter.certificate, attr: state.partnerFilter.attr })
    } else {
      commit('SET_PARTNER_FILTER', { filters: [], certificate: null, attr: false })
    }
  },
  clearObjectFilter({ state, commit }, name) {
    if (name) {
      const objectsFilter = [...state.objectsFilter];
      const filtered = objectsFilter.filter(el => el.group !== name)
      commit('SET_OBJECT_FILTER', filtered)
    } else {
      commit('SET_OBJECT_FILTER', [])
    }
  },
  addVisited({ state, commit }, id) {
    if (state.objects.find(obj => obj.id === id)) {
      commit('ADD_VISITED_OBJECTS', id);
    } else if (state.partners.find(obj => obj.id === id)) {
      commit('ADD_VISITED_PARTNERS', id);
    }
  },
  addDot({ commit }) {
    commit('ADD_DOT');
  },
  removeDot({ commit }) {
    commit('REMOVE_DOT');
  },
  toggleLocationChoice({ commit }) {
    commit('TOGGLE_LOCATION_CHOICE');
  },
  setLocationName({ commit }, name) {
    commit('SET_LOCATION_NAME', name);
  },
  setIsLoading({ commit }, status) {
    commit('SET_LOADING', status);
  }
}
