<template>
  <div id="app">
    <Loader />
    <LayoutSidebar></LayoutSidebar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutSidebar from '@/views/LayoutSidebar.vue';
import Loader from '@/components/Loader.vue'
import axios from '@/axios';

export default {
  name: 'App',
  components: {
    LayoutSidebar,
    Loader
  },
  data() {
    return {
      ...mapGetters
    }
  },
  watch: {
    getActiveLayer: {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal.length !== 0) {
          this.$store.dispatch('setIsLoading', true);
        }

        if (newVal === 'objects' && this.getObjects.length && !this.$route.path.includes('objects/results')) {
          this.$store.dispatch('applyFilters', this.getObjects);
        } else if (newVal === 'objects' && !this.$route.path.includes('objects/results')) {
          await this.$store.dispatch('fetchFacilityTypes');
          await this.parseObjects();
          this.$store.dispatch('applyFilters', this.getObjects);
        }
        if (newVal === 'partners' && this.getPartners.length && !this.$route.path.includes('partners/results')) {
          this.$store.dispatch('applyFilters', this.getPartners);
        } else if (newVal === 'partners' && !this.$route.path.includes('partners/results')) {
          await this.$store.dispatch('fetchPartnerTypes');
          await this.$store.dispatch('fetchPartnerAttributes');
          await this.parsePartners();
          this.$store.dispatch('applyFilters', this.getPartners);
        }

        if (newVal !== oldVal && oldVal.length !== 0) {
          this.$store.dispatch('setIsLoading', false);
        }
      }
    }
  },
  async created() {
    this.$store.dispatch('setIsLoading', true);
    Promise.all([
      this.$store.dispatch('fetchAuthUser'),
      this.$store.dispatch('fetchDirections'),
      this.$store.dispatch('fetchProductGroups'),
      this.$store.dispatch('fetchProducts'),
      this.$store.dispatch('fetchBusinessRegions'),
      this.$store.dispatch('fetchGeoRegions'),
      this.$store.dispatch('fetchSocials'),
      this.$store.dispatch('fetchPartnerTypes'),
      this.$store.dispatch('fetchPartnerAttributes'),
      this.$store.dispatch('fetchFacilityTypes'),
      this.$store.dispatch('fetchPhoneMasks')
    ])
      .then(() => {
        const params = {
          page_size: 100000
        };
        if (!this.isAuth) {
          params.is_active = true;
        }
        return Promise.all([this.parsePartners(params), this.parseObjects(params)]);
      })
      .then(() => {
        let initialLayer = 'partners';
        if (this.$route.path.includes('objects')) {
          initialLayer = 'objects';
        } else if (this.$route.fullPath === '/') {
          this.$router.replace('/partners');
        }
        this.$store.dispatch('setActiveLayer', initialLayer);
        if (this.$route.path.includes('filters') && !this.$route.params.directionIDs) {
          this.$router.replace(`${initialLayer}`);
        }
        this.$store.dispatch('setIsLoading', false);
      });
  },
  methods: {
    prepareMarker(obj) {
      obj.coords = [Number.parseFloat(obj.latitude), Number.parseFloat(obj.longitude)];
      obj.visited = false;
      const objRegions = this.getBusinessRegions.filter(region => obj.business_regions_ids.includes(region.id));
      const objDirectionIds = objRegions.map(region => region.direction_id);
      const objDirections = this.getDirections.filter(direction => objDirectionIds.includes(direction.id));
      obj.directions = objDirections.map(direction => direction.code.toLowerCase());
    },
    async parseObjects(params) {
      const response = await axios.get('/api/v1/objects', { params });
      const objects = response.data.results;
      objects.forEach(object => {
        this.prepareMarker(object);
        object.layer = 'objects';
        object.typeName = this.getObjectTypes.find(type => type.id === object.type).name;
      });

      this.$store.dispatch('setObjects', objects);
    },
    async parsePartners(params) {
      const partnerData = await axios.get('/api/v1/partners', { params });
      const partners = partnerData.data.results;
      // this.$store.dispatch('applyFilters', partners);
      partners.forEach(partner => {
        this.prepareMarker(partner);
        partner.layer = 'partners';
        partner.typeName = this.getPartnerTypes.find(type => type.id === partner.type).name;
      });

      this.$store.dispatch('setPartners', partners);
    }
  },
  computed: {
    ...mapGetters([
      'getActiveLayer',
      'getObjects',
      'getPartners',
      'getDirections',
      'getProducts',
      'getBusinessRegions',
      'getObjectTypes',
      'getPartnerTypes',
      'isAuth',
      'getUser',
      'getPhoneMasks'
    ])
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  overflow: hidden;
}
</style>
