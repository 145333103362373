<template>
  <div :class="{ 'scrollbar': hasScrollbar }" class="sidebar">
    <div :class="{ 'shadow': hasShadow }" class="sidebar-menu">
      <div class="sidebar-top">
        <img src="@/assets/images/svg/logo.svg" alt="REHAU" class="logo" @click="toDefault">
        <span class="city" @click="toggleLocationPopup">{{ region }}</span>
      </div>
      <div class="sidebar-search">
        <Search @query="changeQueryString" @search="setActiveQuery(queryString)" collapse-tags
          :updatedQuery="queryString" place-holder="Город, адрес или название" class="search"
          :class="{ 'visible': queryString.length > 2 }" />
        <ul class="sidebar-search__tags" :class="{ 'visible': queryString.length > 2 }">
          <li v-for="match in queryMatches" :key="match.id" @click="toCard(match.id)">
            <span class="sidebar-search__tag">{{ match.name }}</span>
            <span class="sidebar-search__address">{{ match.address }}</span>
          </li>
        </ul>
      </div>
      <div class="sidebar-select">
        <template>
          <el-tooltip :disabled="hintDirections.split(', ').length < 2" :content="hintDirections" placement="top-start"
            popper-class="hint">
            <el-select class="form-select" placeholder="Выберите направление" :value="chosenDirections" multiple
              @change="toggleSelect($event)">
              <el-option :class="{ 'selected': option.selected }" class="select-checkbox"
                v-for="(option, index) in directions" :key="index" :value="option.code" :label="option.name" />
            </el-select>
          </el-tooltip>
        </template>
        <button
          :class="{ 'fill': $route.path.includes('filters') || (this.partnerFilter.certificate !== null || this.partnerFilter.filters.length || this.objectFilter.length) }"
          class="setting-options" @click="toggleFilter" v-if="this.chosenDirections.length">
          <img src="@/assets/images/svg/close_white.svg" alt="" v-if="$route.path.includes('filters')">
          <img src="@/assets/images/svg/options.svg" alt=""
            v-else-if="this.partnerFilter.certificate !== null || this.partnerFilter.filters.length || this.objectFilter.length">
          <img src="@/assets/images/svg/options_black.svg" alt="" v-else>
        </button>
      </div>
    </div>
    <div class="sidebar-main" @scroll="toggleShadow($event)">
      <router-view :map="this.mapInstance" />
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search.vue';
import partnerFilter from '@/helpers/PartnerFiltes';
import facilityFilter from '@/helpers/FacilityFilter';

export default {
  name: 'Sidebar',
  components: {
    Search
  },
  props: {
    mapInstance: Object
  },
  data() {
    return {
      hasShadow: false,
      queryString: '',
      activeQuery: ''
    }
  },
  async mounted() {
    let status = await this.getRegion();
    if (!status) {
      const retry = setInterval(async () => {
        status = await this.getRegion();
        if (status) { clearInterval(retry) }
      }, 3000);
    }
  },
  methods: {
    async getRegion() {
      try {
        const result = await window.ymaps.geocode(this.location, { results: 1, kind: 'locality' })
        const object = result.geoObjects.get(0);
        this.$store.dispatch('setLocationName', object.getAdministrativeAreas()[0]);
        return 'ok';
      } catch {
        return null;
      }
    },
    toDefault() {
      if (this.activeLayer === 'objects') {
        this.$store.dispatch('applyFilters', this.objects);
      } else if (this.activeLayer === 'partners') {
        this.$store.dispatch('applyFilters', this.partners);
      }
      this.queryString = '';
      this.activeQuery = '';
      this.$store.dispatch('clearObjectFilter');
      this.$store.dispatch('clearPartnerFilter');
      this.$store.dispatch('setSearchResults', []);
      this.$store.dispatch('setActiveDirections', []);
      if (this.$route.fullPath !== '/') {
        this.$router.push(`/${this.activeLayer}`);
      }
      this.$store.dispatch('setVisible', this.mapInstance.getBounds())
      this.filterShow = false;
    },
    toggleFilter() {
      if (this.chosenDirections.length && !this.$route.path.includes('filters')) {
        const isResult = this.$route.path.includes('results');
        this.$router.push(`/${this.activeLayer}/filters`);
        let filtered = [];
        if (this.activeLayer === 'objects') {
          const savedFilters = this.$store.getters['getObjectFilter']
          const savedRange = this.$store.getters['getObjectRangeFilter']
          if (savedFilters.length) {
            const filter = savedFilters.reduce((a, v) => {
              a.typeNames = v.group === 'Вид объекта' ? v.names : a.typeNames
              a.productIds = v.productIds.filter(el => el).length ? [...a.productIds, ...v.productIds] : a.productIds
              return a
            }, {
              typeNames: [],
              productIds: []
            })
            if (!isResult) {
              filtered = facilityFilter(this.objects, filter, savedRange, this.chosenDirections)
            }
          } else if (!isResult) {
            filtered = this.objects.filter(obj => obj.directions.some(dir => this.chosenDirections.includes(dir.toUpperCase())));
          }
        } else if (this.activeLayer === 'partners') {
          const savedFilters = this.$store.getters['getPartnerFilter']

          if (savedFilters.length) {
            const filter = {
              ...this.filterList,
              filters: this.filterList.filters.reduce((a, v) => {
                a.typeNames = v.group === 'Тип партнера' ? v.names : a.typeNames
                a.productIds = v.productIds.filter(el => el).length ? [...a.productIds, ...v.productIds] : a.productIds
                return a
              }, {
                typeNames: [],
                productIds: []
              })
            }

            if (!isResult) {
              filtered = partnerFilter(this.partners, filter, this.chosenDirections)
            }
          } else if (!isResult) {
            filtered = this.partners.filter(partner => partner.directions.some(dir => this.chosenDirections.includes(dir.toUpperCase())));
          }
        }
        if (!isResult) {
          this.$store.dispatch('applyFilters', filtered);
        }
      } else if (this.$route.path.includes('filters')) {
        this.toDefault()
      }
    },
    changeQueryString(input) {
      this.queryString = input ? input.trim() : '';
    },
    setActiveQuery(query) {
      if (query) {
        this.activeQuery = query;
        this.queryString = query;
        this.toResults();
      } else {
        this.activeQuery = '';
        this.toDefault();
      }
    },
    toResults(id = null) {
      this.$store.dispatch('setSearchResults', []);
      let results = [];
      if (id) {
        let result;
        if (this.activeLayer === 'objects') {
          result = this.objects.find(obj => obj.id === id);
        } else if (this.activeLayer === 'partners') {
          result = this.partners.find(partner => partner.id === id);
        }
        results.push(result);
      } else {
        results = [...this.queryMatches];
      }
      if (results.length) {
        this.$store.dispatch('setSearchResults', results);
        this.$store.dispatch('applyFilters', results);
      } else {
        window.ymaps.geocode(this.queryString, { results: 1, kind: 'house' })
          .then(result => {
            const object = result.geoObjects.get(0);
            const coords = object.geometry.getCoordinates();
            this.$store.dispatch('setCurrentLocation', { coords, zoom: 10 });
            this.$store.dispatch('addDot');
          });
      }
      this.queryString = '';
      this.$router.push('/results');
    },
    toCard(id) {
      this.queryString = '';
      this.$store.dispatch('setActiveItem', id);
      this.$router.push(`/${this.activeLayer}/${id}`);
    },
    toggleShadow(event) {
      this.hasShadow = !!event.target.scrollTop
    },
    toggleSelect(value) {
      let filtered = [];
      this.$store.dispatch('setIsLoading', true);
      this.$store.dispatch('clearObjectFilter');
      this.$store.dispatch('clearPartnerFilter');
      if (this.activeLayer === 'objects') {
        filtered = this.objects.filter(obj => obj.directions.some(dir => value.includes(dir.toUpperCase())));
      } else if (this.activeLayer === 'partners') {
        filtered = this.partners.filter(partner => partner.directions.some(dir => value.includes(dir.toUpperCase())));
      }
      if (this.$route.path.includes('filters')) {
        this.$store.dispatch('applyFilters', filtered);
      }
      this.$store.dispatch('setActiveDirections', value);

      if (!this.$route.path.includes('filters')) {
        this.toggleFilter();
      }
      this.$store.dispatch('setIsLoading', false);
    },
    toggleLocationPopup() {
      this.$store.dispatch('toggleLocationChoice');
    }
  },
  computed: {
    hasScrollbar() {
      return this.$route.meta.hasScrollbar
    },
    location() {
      return this.$store.getters['getCoords'];
    },
    region() {
      return this.$store.getters['getLocationName'];
    },
    objects() {
      return this.$store.getters['getObjects'];
    },
    partners() {
      return this.$store.getters['getPartners'];
    },
    queryMatches() {
      const regex = new RegExp(this.queryString.toLowerCase());
      let items;
      if (this.activeLayer === 'objects') {
        items = this.objects;
      } else if (this.activeLayer === 'partners') {
        items = this.partners;
      }
      let matches = [];
      if (items && items.length) {
        matches = items.filter(obj => regex.test(obj.name.toLowerCase()) || regex.test(obj.address.toLowerCase()));
      }
      return matches;
    },
    directions() {
      return this.$store.getters['getDirections'];
    },
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    },
    chosenDirections() {
      return this.$store.getters['getActiveDirections'];
    },
    hintDirections() {
      const hints = this.directions.filter(dir => this.chosenDirections.includes(dir.code));
      return hints.map((hint) => hint.name).join(', ');
    },
    partnerFilter() {
      return this.$store.getters['getPartnerFilter']
    },
    objectFilter() {
      return this.$store.getters['getObjectFilter']
    },
    searchResults() {
      return this.$store.getters['getSearchResults'];
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  position: relative;
  height: 100vh;
  width: 393px;
  background: #FFFFFF;
  box-shadow: 3px 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.sidebar-menu {
  padding: 10px;
  padding-top: 20px;
}

.sidebar-main {
  flex-grow: 1;
  max-height: calc(100vh - 210px);
}

.scrollbar {
  margin-right: 5px;
  overflow-y: auto;
}

.sidebar-main::-webkit-scrollbar {
  width: 5px;
}

.sidebar-main::-webkit-scrollbar-thumb {
  background-color: black;
  margin-top: 42px;
}

.sidebar-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 26px;
}

.sidebar-search {
  position: relative;
}

.sidebar-search__tags {
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease;
}

.sidebar-search__tags.visible {
  opacity: 1;
  pointer-events: all;
  z-index: 5;
}

.sidebar-search__tags li {
  padding: 9px 20px 13px;
  background-color: #fff;
  color: #000;
  transition: background-color .3s ease, color .3s ease;
  cursor: pointer;
}

.sidebar-search__tags li:hover {
  background-color: #58BCAF;
  color: #fff;
}

.sidebar-search__tag {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.sidebar-search__address {
  display: block;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo {
  width: 140px;
  height: 45px;
  cursor: pointer;
}

.city {
  font-size: 18px;
  color: #DC143C;
  cursor: pointer;
  margin-bottom: 5px;
}

.sidebar-select {
  position: relative;
  display: flex;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.select-checkbox::after {
  content: '';
  position: absolute;
  top: 17px;
  left: 14px;
  width: 12px;
  height: 12px;
  background: url('../assets/images/svg/checked.svg') no-repeat;
  opacity: 0;
  z-index: 2;
}

.el-select-dropdown.is-multiple .__item.select-checkbox.selected::after {
  opacity: 1;
}

.setting-options {
  width: 46px;
  height: 42px;
  padding: 0;
  background-color: #FFFFFF;
  border: 1px solid #000000;
  cursor: pointer;
  transition: background-color .5s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 26px;
    height: 26px;
  }
}

.setting-options.fill {
  background-color: #58BCAF;
  border-color: #58BCAF;
}

.options-icon {
  width: 26px;
  height: 26px;
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1024px) {
  .sidebar {
    overflow-y: hidden;
  }
}

@media (max-width: 1023px) {
  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 1;
  }

  .sidebar-menu {
    padding-top: 10px;
  }

  .logo {
    width: 117px;
    height: 37px;
  }

  .sidebar-top {
    margin-bottom: 20px;
    align-items: flex-end;
  }

  .city {
    margin-bottom: 2px;
  }
}

@media (max-height: 499px) {
  .sidebar-top {
    display: none;
  }
}
</style>
